import React, { useState, useEffect, useRef, memo } from 'react';
import { Field } from 'react-final-form';
import css from './FieldFilteredSelect.module.css';

const FieldFilteredSelectComponent = (props) => {

  const {
    // input: { onChange, value },
    input,
    label,
    placeholder,
    options,
    disabled,
    noItemFoundPlaceholder,
    icon,
    setInputTreatments,
    name,
  } = props;
  const wrapperRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredItems = searchTerm && options?.filter(
    (item) =>
      item.option.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.label.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      setInputTreatments(filteredItems);
    } else {
      setInputTreatments([]);
    }
    setIsEditing(true);
  };

  const handleOptionClick = (o) => {
    input.onChange(o);
    setSearchTerm(o.label);
    setInputTreatments([o]);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      input.onChange(e);
      // setSearchTerm('');
      // setInputTreatments([e]);
      setIsEditing(false);
    }
  };

  return (
    <div className={css.wrapper}>
      {label && <label className={css.label}>{label}</label>}
      <div className={css.selectInput} ref={wrapperRef} tabIndex="0">
        <input
          type="text"
          key={name}
          name={name}
          disabled={disabled}
          value={searchTerm}
          placeholder={placeholder}
          onChange={handleInputChange}
          // onKeyDown={handleKeyDown}
          style={icon ? { paddingRight: 48 } : { paddingRight: 12 }}
        />
        {icon && <img className={css.icon} src={icon} />}
        {searchTerm && isEditing && (
          <div className={css.options}>
            {filteredItems.length > 0 ? (
              filteredItems.map((o) => (
                <div
                  key={o.option}
                  className={css.option}
                  onClick={() => handleOptionClick(o)}
                >
                  {o.label}
                </div>
              ))
            ) : (
              <p className={css.noItem}>{noItemFoundPlaceholder}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const FieldFilteredSelect = ({
  name,
  label,
  placeholder,
  id,
  options,
  disabled,
  noItemFoundPlaceholder,
  icon,
  setInputTreatments
}) => (
  <Field
    name={name}
    component={FieldFilteredSelectComponent}
    label={label}
    placeholder={placeholder}
    id={id}
    options={options}
    disabled={disabled}
    noItemFoundPlaceholder={noItemFoundPlaceholder}
    icon={icon}
    setInputTreatments={setInputTreatments}
  />
);

export default memo(FieldFilteredSelect);
