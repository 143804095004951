import React from 'react';
import css from './TreatmentCard.module.css';

const TreatmentCard = props => {
    const { image, option, label, description } = props;
    return (
        <div className={css.root}>
            <img className={css.image} src={image} />
            <p className={css.title}>{label}</p>
            <p className={css.description}>{description}</p>
        </div>
    )
};

export default TreatmentCard;