import React, { useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { useConfiguration } from '../../../../context/configurationContext';
import { H2, Form, FieldSelectCustom } from '../../../../components';
import FieldFilteredSelect from '../../../../components/FieldFilteredSelect/FieldFilteredSelect';
import TreatmentCard from '../../../../components/TreatmentCard/TreatmentCard';
import { treatmentsData } from '../../../../components/TreatmentCard/treatments';

import css from './SectionSearch.module.css';
import searchIcon from './img/searchIcon.png'
import arrow from './img/arrow.png'


const SectionSearch = props => {
  const intl = useIntl();
  const [inputTreatments, setInputTreatments] = useState(treatmentsData);
  const [filteredTreatments, setFilteredTreatments] = useState(treatmentsData);

  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionSearch.title" />
      </H2>
      <p className={css.info}>
        <FormattedMessage id="SectionSearch.info" />
      </p>

      <FinalForm
        {...props}
        onSubmit={() => { }}
        render={formRenderProps => {
          const { values } = formRenderProps;

          const [visibleCount, setVisibleCount] = useState(12);

          const showMore = () => {
            setVisibleCount(prevCount => prevCount + 12);
          };

          const showFewer = () => {
            setVisibleCount(prevCount => (prevCount - 12 > 12 ? prevCount - 12 : 12));
          };

          useEffect(() => {
            if (inputTreatments && inputTreatments.length > 0) {
              const selectedOptions = inputTreatments.map(type => type.option);
              setFilteredTreatments(
                treatmentsData.filter(treatment =>
                  selectedOptions.includes(treatment.option)
                )
              );
            } else {
              setFilteredTreatments(treatmentsData);
            }
          }, [inputTreatments]);


          const handleSearch = () => {

            let tehnique = ''
            let client = ''
            let location = ''
            let session = ''
            let language = ''

            if (values.techniqueType) {
              tehnique = `pub_tehnique=${values.techniqueType}`;
            }

            //To do the rest after filters implement


            // pushToPath(`/s?keywords=${inputValue}`);
          };

          const config = useConfiguration();
          const listingFieldsConfig = config.listing.listingFields;
          const techniqueTypes = listingFieldsConfig.find(c => c.key === 'techniques').enumOptions;
          const clientCategories = listingFieldsConfig.find(c => c.key === 'clientCategories').enumOptions;
          const offerLocation = listingFieldsConfig.find(c => c.key === 'offerLocation').enumOptions;
          const sessionDynamics = listingFieldsConfig.find(c => c.key === 'sessionDynamics').enumOptions;
          const languages = listingFieldsConfig.find(c => c.key === 'languages').enumOptions;

          return (
            <Form className={css.formRoot} onSubmit={() => { }}>
              <div className={css.formContainer}>
                <FieldFilteredSelect
                  id="techniqueType"
                  name={`techniqueType`}
                  options={techniqueTypes}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.searchPlaceholder' })}
                  noItemFoundPlaceholder={intl.formatMessage({ id: 'SectionSearch.noTehniqueFound' })}
                  icon={searchIcon}
                  setInputTreatments={setInputTreatments}
                />
                <FieldSelectCustom
                  id="clientCategories"
                  name="clientCategories"
                  icon={arrow}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.bestSuitedPlaceholder' })}
                >
                  {clientCategories.map((o, index) =>
                    <option key={o} className={css.option} value={o}>{o.label}</option>
                  )}
                </FieldSelectCustom>
                <FieldSelectCustom
                  id="offerLocation"
                  name="offerLocation"
                  icon={arrow}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.sessionTypePlaceholder' })}
                >
                  {offerLocation.map((o, index) =>
                    <option key={o} className={css.option} value={o}>{o.label}</option>
                  )}
                </FieldSelectCustom>
                <FieldSelectCustom
                  id="sessionDynamics"
                  name="sessionDynamics"
                  icon={arrow}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.sessionDynamicsPlaceholder' })}
                >
                  {sessionDynamics.map((o, index) =>
                    <option key={o} className={css.option} value={o}>{o.label}</option>
                  )}
                </FieldSelectCustom>
                <FieldSelectCustom
                  id="language"
                  name="language"
                  icon={arrow}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.languagePlaceholder' })}
                >
                  {languages.map((o, index) =>
                    <option key={o} className={css.option} value={o}>{o.label}</option>
                  )}
                </FieldSelectCustom>

                <div
                  className={css.searchButton}
                  onClick={handleSearch}
                >
                  Search
                </div>
              </div>

              <div className={css.treatmentsRoot}>
                <div className={css.treatmentsContainer}>

                  {/* {treatmentsData.slice(0, visibleCount).map((treatment, index) => ( */}
                  {filteredTreatments.slice(0, visibleCount).map((treatment, index) => (
                    <TreatmentCard
                      key={index}
                      image={treatment.image}
                      label={treatment.label}
                      description={treatment.description}
                    />
                  ))}
                </div>
                <div className={css.buttonContainer}>
                  {visibleCount > 12 && (
                    <a onClick={showFewer} className={css.showButton} style={{ left: 0 }}>
                      Show fewer
                    </a>
                  )}
                  {visibleCount < filteredTreatments.length && (
                    <a onClick={showMore} className={css.showButton} style={{ right: 0 }}>
                      Show more
                    </a>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SectionSearch;
